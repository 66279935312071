import { useEffect, useState, forwardRef, useRef } from 'react';
import { updateDoc, arrayUnion, getDocs, doc, collection, addDoc, getDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db } from '../../api/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import '../../index.css';
import KonvaToolsAppBar from '../../pages/sketch/components/KonvaToolsAppBar';
import KonvaDrawSpace from '../../pages/sketch/components/KonvaDrawSpace.js';
import useNetwork from '../../components/useNetwork.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//alert dialog for reloading after lost connection
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import imageCompression from 'browser-image-compression';
import html2canvas from 'html2canvas';
import { createFile as sendImageToJobNimbus } from '../../api/jobnimbus';
import { createAttachmentNote } from '../../api/jobber';
const { updateDrawing, addNewDrawing, getOrganizationDrawings } = require('../../api/drawing');
const { uploadImageToCloudflare } = require('../../api/cloudflare');
const { notify } = require('../../utils/notification.js');

const Alert = forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

function Sketch() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const sketch = useSelector((state) => state.sketch);
  const saveData = useSelector((state) => state.sketch.saveData);

  const [currentTool, setCurrentTool] = useState(null);
  const [recovered, setRecovered] = useState(false);
  const [recoveryPromptSent, setRecoveryPromptSent] = useState(false);
  const [storeRecoveryData, setStoreRecoveryData] = useState(false);
  const [snackbar, setSnackbar] = useState({
    severity: 'success',
    message: 'Success!'
  });
  const [alert, setAlert] = useState({
    title: '',
    text: '',
    rejectText: '',
    acceptText: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [config, setConfig] = useState({});
  const networkState = useNetwork();
  const { online } = networkState;
  const [searchParams] = useSearchParams();

  const [selected, setSelected] = useState(null);
  const [objects, setObjects] = useState([]);
  const [groupOptions, setGroupOptions] = useState([
    { name: 'A', color: '#4D4D22', description: 'Fence' },
    { name: 'B', color: '#1B9E77', description: 'Fence' },
    { name: 'C', color: '#D95F02', description: 'Fence' },
    { name: 'D', color: '#7570B3', description: 'Fence' },
    { name: 'E', color: '#E7298A', description: 'Fence' },
    { name: 'F', color: '#66A61E', description: 'Fence' }
  ]);
  const [defaultGroupOption, setDefaultGroupOption] = useState({ name: 'A', color: '#4D4D22', description: 'Fence' });
  const [legendVisible, setLegendVisible] = useState(false);
  const legendRef = useRef(null);

  useEffect(async () => {
    if (!auth.user) return;
    if (searchParams.get('id')) {
      console.log(searchParams.get('id'));
      setObjects([]);
      //setSaveData({});
      //dispatch({ type: 'CLEAR_SAVE_DATA' });
      const organizationId = auth.user.organization;

      // Check if organizationId is set
      if (!organizationId) return;

      // Fetch the drawings from the organization's "drawings" subcollection
      const organizationDocRef = doc(db, 'organizations', organizationId, 'drawings', searchParams.get('id'));

      getDoc(organizationDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            // Process the retrieved document data
            // console.log(data);
            let objectsToLoad = JSON.parse(data.saveDataObject);

            let contact = JSON.parse(data.contact);
            let drawingId = data.id;
            setConfig({ ...config, drawingId: drawingId });
            const loadObjectsEvent = new CustomEvent('loadObjects', {
              detail: { data: objectsToLoad }
            });
            document.dispatchEvent(loadObjectsEvent);
            dispatch({
              type: 'SET_CONTACT',
              data: contact
            });
          } else {
            // Document does not exist
            console.log('Document does not exist');
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error('Error fetching document:', error);
        });
    }
  }, [auth.user]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // Calls onFocus when the window first loads
    //onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    //let TRUE know we are ready for external commands
    window.addEventListener('message', receiveExternalCommand);
    performScript('TRUEDraw3 | On Load', {}, 5);

    return () => {
      window.removeEventListener('message', receiveExternalCommand);
    };
  }, []);

  useEffect(() => {
    if (!online) {
      saveToLocalStorage({
        severity: 'info',
        message: 'Connection lost - storing unsaved changes',
        close: true
      });
    }
  }, [online]);

  useEffect(() => {
    //already prompted
    if (recoveryPromptSent) {
      return;
    }

    //the recovery data is from this session
    if (storeRecoveryData) {
      return;
    }

    let localData = localStorage.getItem('lastDrawingSession');
    if (localData === null) {
      setStoreRecoveryData(true);
      return;
    }

    if (!searchParams.get('id')) {
      handleOpenAlert({
        title: '',
        text: 'Detected unsaved changes from a previous session. Would you like to recover those changes?',
        rejectText: 'No',
        acceptText: 'Yes'
      });
    }
  }, [config, objects]);

  useEffect(() => {
    if (storeRecoveryData) {
      saveToLocalStorage({});
    }
  }, [saveData]);

  // useEffect(() => {
  //   //redrawStage();
  //   console.log(objects);
  // }, [objects.length]);

  const saveToLocalStorage = (options) => {
    let saveDataObject = getSaveDataObject(options.severity, options.message);
    //let saveDataObject = saveData;

    if (saveDataObject.length) {
      localStorage.setItem('lastDrawingSession', JSON.stringify(saveDataObject));
    }

    if (options.close) {
      performScript('TRUEDraw3 | Close', {}, 0);
    }
  };

  // User has switched back to the tab
  const onFocus = () => {
    //window.alert('focused');
    setCurrentTool('fence');
    setCurrentTool('pan');
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    //window.alert('blurred');
  };

  const copy = async () => {
    try {
      const dataURL = await getDataURL();
      notify('success', 'Success', `Image has been copied to clipboard`);
      return dataURL;
    } catch (error) {
      console.error('Error during canvas copy:', error);
      return notify('danger', 'Error', `Error during canvas copy: ${error.message}`);
    }
  };

  const download = async () => {
    try {
      let drawingId = config.drawingId || objects[0].key;
      const dataURL = await getDataURL();
      // Create a link and trigger the download
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `${drawingId}.jpg`; // You can use '.jpg' for JPEG format
      link.click();
    } catch (error) {
      console.error('Error during canvas download:', error);
    }
  };

  const getDataURL = async () => {
    const stage = sketch.saveData.stageRef.current;

    // Find the layer containing the drawing shapes
    const drawingLayer = stage.children.find((layer) =>
      layer.getChildren().some((element) => {
        return element.getClassName() !== 'Line';
      })
    );

    // Calculate the bounding box of all drawing shapes
    const shapesBoundingBox = drawingLayer.getClientRect();

    // Define letter paper dimensions (at 300 DPI)
    const LETTER_WIDTH = 2550; // 8.5" × 300 DPI
    const LETTER_HEIGHT = 3300; // 11" × 300 DPI
    const MARGIN = 50; // 0.5" margin at 300 DPI

    if (legendVisible) {
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = LETTER_WIDTH;
      tempCanvas.height = LETTER_HEIGHT;

      const tempCtx = tempCanvas.getContext('2d');

      // Fill with white background
      tempCtx.fillStyle = '#FFFFFF';
      tempCtx.fillRect(0, 0, LETTER_WIDTH, LETTER_HEIGHT);

      // Calculate scaling to fit content within margins while maintaining aspect ratio
      const contentWidth = shapesBoundingBox.width;
      const contentHeight = shapesBoundingBox.height;
      const availableWidth = LETTER_WIDTH - 2 * MARGIN;
      const availableHeight = LETTER_HEIGHT - 2 * MARGIN - 200; // Extra space for legend

      const scale = Math.min(availableWidth / contentWidth, availableHeight / contentHeight);

      // Calculate centered position
      const scaledWidth = contentWidth * scale;
      const scaledHeight = contentHeight * scale;
      const centerX = (LETTER_WIDTH - scaledWidth) / 2;
      const centerY = (LETTER_HEIGHT - scaledHeight - 200) / 2; // Adjust for legend

      // Draw scaled content
      stage.getChildren((layer) => {
        const layerCanvas = layer.toCanvas();
        const relativeX = Math.floor(layer.x() - shapesBoundingBox.x);
        const relativeY = Math.floor(layer.y() - shapesBoundingBox.y);

        tempCtx.save();
        tempCtx.translate(centerX, centerY);
        tempCtx.scale(scale, scale);
        tempCtx.drawImage(layerCanvas, relativeX, relativeY);
        tempCtx.restore();
      });

      // Handle legend
      const legendDataURL = await new Promise((resolve) => {
        const element = document.getElementById('legend');
        html2canvas(element).then((canvas) => {
          resolve(canvas.toDataURL('image/jpeg'));
        });
      });

      const legendImage = new Image();
      await new Promise((resolve, reject) => {
        legendImage.onload = resolve;
        legendImage.onerror = reject;
        legendImage.src = legendDataURL;
      });

      // Scale legend to fit at bottom
      const legendWidth = LETTER_WIDTH * 0.3; // 30% of page width
      const legendHeight = (legendWidth / legendImage.width) * legendImage.height;
      const legendX = LETTER_WIDTH - legendWidth - MARGIN;
      const legendY = LETTER_HEIGHT - legendHeight - MARGIN;

      tempCtx.drawImage(legendImage, legendX, legendY, legendWidth, legendHeight);
      return tempCanvas.toDataURL('image/jpeg', 0.95);
    }

    // Handle non-legend case
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = LETTER_WIDTH;
    tempCanvas.height = LETTER_HEIGHT;

    const tempCtx = tempCanvas.getContext('2d');
    tempCtx.fillStyle = '#FFFFFF';
    tempCtx.fillRect(0, 0, LETTER_WIDTH, LETTER_HEIGHT);

    // Calculate scaling to fit content within margins
    const scale = Math.min((LETTER_WIDTH - 2 * MARGIN) / shapesBoundingBox.width, (LETTER_HEIGHT - 2 * MARGIN) / shapesBoundingBox.height);

    // Center the content
    const scaledWidth = shapesBoundingBox.width * scale;
    const scaledHeight = shapesBoundingBox.height * scale;
    const centerX = (LETTER_WIDTH - scaledWidth) / 2;
    const centerY = (LETTER_HEIGHT - scaledHeight) / 2;

    // Draw scaled content
    stage.getChildren((layer) => {
      const layerCanvas = layer.toCanvas();
      const relativeX = Math.floor(layer.x() - shapesBoundingBox.x);
      const relativeY = Math.floor(layer.y() - shapesBoundingBox.y);

      tempCtx.save();
      tempCtx.translate(centerX, centerY);
      tempCtx.scale(scale, scale);
      tempCtx.drawImage(layerCanvas, relativeX, relativeY);
      tempCtx.restore();
    });

    return tempCanvas.toDataURL('image/jpeg', 0.95);
  };

  const upload = async () => {
    let fileName;
    let imageFile;

    let drawingId = config.drawingId || objects[0].key;

    let trimmedCanvas = await getDataURL();

    let userData = auth.user;

    let dataSource = Object.keys(userData).filter((e, index) => {
      if (e.includes('Integration') && userData[e].enabled) {
        return e;
      }
    });

    let integration = dataSource[0];

    switch (integration) {
      case 'jobNimbusIntegration':
        fileName = drawingId + '.png';
        //imageFile = await imageCompression.canvasToFile(trimmedCanvas, 'image/png', fileName, Date.now(), 0.5);
        imageFile = trimmedCanvas;
        console.log(imageFile);
        //const base64result = await imageCompression.getDataUrlFromFile(imageFile);
        //console.log(base64result);
        sendImageToJobNimbus(auth.user.jobNimbusIntegration.apiKey, imageFile, fileName, sketch.contact.jnid);

        break;
      case 'jobberIntegration':
        fileName = drawingId + '.webp';
        imageFile = await imageCompression.canvasToFile(trimmedCanvas, 'image/webp', fileName, Date.now(), 0.5);
        let shareableUrl = await uploadImageToCloudflare(imageFile);
        shareableUrl = `https://imagedelivery.net/9Nt51ve_BjQZ7bVcK-tkLA/${shareableUrl.id}/anim=false,format=webp,quality=100,metadata=keep`;
        let access_token = userData[dataSource].access_token;
        //console.log(userData[dataSource])
        createAttachmentNote(access_token, shareableUrl, sketch.contact.id);
        break;

      default:
        break;
    }

    return;
  };

  const save = async (contact) => {
    //return notify('success', 'Success', 'Drawing uploaded');

    let drawingId = config.drawingId || objects[0].key;

    // Use the temporary canvas for exporting
    const dataURL = await getDataURL();

    //const dataURL = trimmedCanvas.toDataURL('image/png', 0.1);
    let saveDataObject = getSaveDataObject('info', 'Saving drawing');
    console.log(saveDataObject);
    //console.log(resizeFile(dataURL));
    const organizationId = auth.user.organization;
    // const organizationRef = doc(db, 'organizations', organizationId);
    // const drawingsCollectionRef = collection(organizationRef, 'drawings');

    updateDrawing(organizationId, {
      id: drawingId,
      saveDataObject: JSON.stringify(saveDataObject),
      thumbnail: dataURL,
      contact: JSON.stringify(contact),
      creator: auth.user.displayName,
      lastUpdated: Date.now()
    });
    localStorage.removeItem('lastDrawingSession');
    return;
  };

  const saveAsNew = async (contact) => {
    //return console.log(auth);
    //return addDrawing(auth.user.ref, { drawings: [{ test: 'test' }] });
    let drawingId = getRandomId();

    let saveDataObject = getSaveDataObject('info', 'Saving drawing');

    const dataURL = await getDataURL();

    addNewDrawing(auth.user.organization, {
      id: drawingId,
      name: contact.display_name,
      saveDataObject: JSON.stringify(saveDataObject),
      thumbnail: dataURL,
      contact: JSON.stringify(contact),
      creator: auth.user.displayName,
      created: Date.now(),
      lastUpdated: Date.now()
    });

    localStorage.removeItem('lastDrawingSession');
    return;
  };

  const getSaveDataObject = () => {
    let newSaveData = [];
    const keys = Object.keys(saveData);
    //const keys = Object.keys(sketch.objects);
    // console.log(objects);
    // console.log(keys);
    for (var i = 0; i < keys.length; i++) {
      if (keys[i] === 'stageRef') {
        //skip
      } else {
        newSaveData.push(saveData[keys[i]]);
      }
    }
    //console.log(newSaveData);
    return newSaveData;
  };

  const close = () => {
    performScript('TRUEDraw3 | Close', {}, 0);
  };

  const receiveExternalCommand = (command) => {
    try {
      command = JSON.parse(command.data);
      if (command.isTrue === true) {
        const functionName = command.functionName;
        const data = command.data;
        switch (functionName) {
          case 'loadObjects':
            if (recovered) {
              //skip
            } else {
              console.log(data);
              createLoadObjectsEvent(data);
            }
            break;
          case 'loadImageOfAddress':
            createLoadImageOfAddressEvent(data);
            break;
          case 'loadUploadedImage':
            createLoadUploadedImageEvent(data);
            break;
          case 'config':
            setConfig(data);
            break;
          default:
            break;
        }
      }
    } catch (e) {}
  };

  const performScript = (scriptName, parameter, options) => {
    const message = {
      scriptName: scriptName,
      parameter: parameter,
      options: options,
      true: true
    };
    window.parent.postMessage(JSON.stringify(message), '*');
  };

  const createLoadObjectsEvent = (data) => {
    const loadObjectsEvent = new CustomEvent('loadObjects', {
      detail: { data: data }
    });
    document.dispatchEvent(loadObjectsEvent);
  };

  const createLoadImageOfAddressEvent = (data) => {
    const loadImageOfAddressEvent = new CustomEvent('loadImageOfAddress', {
      detail: { data: data }
    });
    document.dispatchEvent(loadImageOfAddressEvent);
  };

  const createLoadUploadedImageEvent = (data) => {
    const loadUploadedImageEvent = new CustomEvent('loadUploadedImage', {
      detail: { data: data }
    });
    document.dispatchEvent(loadUploadedImageEvent);
  };

  const handleOpenSnackbar = (options) => {
    setSnackbar(options);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbarEvent = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenAlert = (options) => {
    setAlert(options);
    setRecoveryPromptSent(true);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);

    localStorage.removeItem('lastDrawingSession');
    setStoreRecoveryData(true);
  };

  const handleRecoverUnsavedChanges = () => {
    let localData = localStorage.getItem('lastDrawingSession');
    localData = JSON.parse(localData);
    setObjects([]);

    setOpenAlert(false);
    createLoadObjectsEvent(localData);
    setStoreRecoveryData(true);
  };

  const getRandomId = () => {
    return Math.random().toString(36).slice(2);
  };
  //console.log(sketch.saveData);
  return (
    <div>
      <KonvaToolsAppBar
        currentTool={currentTool}
        setCurrentTool={setCurrentTool}
        objects={objects}
        setObjects={setObjects}
        deleteObject={(id) => {
          console.log(id);
          console.log(objects);
          return setObjects(objects.filter((e) => e.key !== id));
        }}
        getRandomId={getRandomId}
        saveData={saveData}
        setSaveData={(e, preventHistoryIncrement) => {
          console.log(e, preventHistoryIncrement);
          return dispatch({ type: 'ADD_OBJECT_TO_SAVE_DATA', data: e, preventHistoryIncrement: preventHistoryIncrement });
        }}
        addObjectToSaveData={(e) => dispatch({ type: 'ADD_OBJECT_TO_SAVE_DATA', data: e })}
        updateObjectInSaveData={(e) => dispatch({ type: 'UPDATE_OBJECT_IN_SAVE_DATA', data: e })}
        clearSaveData={() => dispatch({ type: 'CLEAR_SAVE_DATA' })}
        getSaveDataObject={getSaveDataObject}
        copy={copy}
        save={save}
        saveAsNew={saveAsNew}
        download={download}
        upload={upload}
        close={close}
        performScript={performScript}
        handleOpenSnackbar={handleOpenSnackbar}
        config={config}
        showTools={true}
        setSelected={setSelected}
        selected={selected}
      />
      <KonvaDrawSpace
        setCurrentTool={setCurrentTool}
        currentTool={currentTool}
        objects={objects}
        groupOptions={groupOptions}
        setGroupOptions={setGroupOptions}
        defaultGroup={defaultGroupOption}
        setDefaultGroup={setDefaultGroupOption}
        legendRef={legendRef}
        legendVisible={legendVisible}
        setLegendVisible={setLegendVisible}
        setObjects={setObjects}
        deleteObject={(id) => {
          setObjects((prevState) => {
            return prevState.filter((e) => e.key !== id);
          });
          return dispatch({ type: 'DELETE_OBJECT_FROM_SAVE_DATA', data: id });
        }}
        getRandomId={getRandomId}
        saveData={saveData}
        setSaveData={(e, preventHistoryIncrement) => {
          return dispatch({ type: 'ADD_OBJECT_TO_SAVE_DATA', data: e, preventHistoryIncrement: preventHistoryIncrement });
        }}
        addObjectToSaveData={(e) => dispatch({ type: 'ADD_OBJECT_TO_SAVE_DATA', data: e })}
        updateObjectInSaveData={(e) => dispatch({ type: 'UPDATE_OBJECT_IN_SAVE_DATA', data: e })}
        config={config}
        setSelected={setSelected}
        selected={selected}
      />
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbarEvent}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbarEvent}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{alert.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAlert}
            color="primary"
          >
            {alert.rejectText}
          </Button>
          <Button
            onClick={handleRecoverUnsavedChanges}
            color="primary"
            autoFocus
          >
            {alert.acceptText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Sketch;
